import { serializeForm } from './util/form';
import createUrlObject from './util/url-helper';
import deUglifySensitiveStrings from './util/deuglify-strings';

export default function initalizeAllEmployees() {
	const allEmployees = getNodeById('all-employees-page');
	if (!allEmployees) {
		return;
	}
	deUglifySensitiveStrings(allEmployees);

	document.on('click', ({ target }) => {
		if ([...target.classList].includes('show-phone-button')) {
			target.classList.add('hidden');
			getNodeById(target.dataset['name']).classList.add('hidden');
			getNodeById(target.dataset['phone']).classList.remove('hidden');
		}
	});
	const [form] = allEmployees.$('form');
	const departmentSelect = getNodeById('employees-departments-list');
	const sectionSelect = getNodeById('employees-sections-list');
	const listingDomNode = getNodeById('all-employees-listing');
	const loadMoreButton = getNodeById('load-more-employees');
	const searchinput = getNodeById('all-employees-options-input');
	const searchButton = getNodeById('remove-button');
	const buttonIcon = getNodeById('button-icon');
	const sectionsDomNode = getNodeById('employees-sections-list');
	const departmentsDomNode = getNodeById('employees-departments-list');

	const { basePath } = createUrlObject(window.location.href);

	changeSearchInput();

	loadMoreButton.on('click', getLoadMoreClickHandler());

	function getLoadMoreClickHandler() {
		return (e) => {
			e.preventDefault();
			const page = parseInt(loadMoreButton.dataset['page'], 10);
			loadMoreButton.dataset['page'] = page + 1;
			fetchEmployees(false);
			return false;
		};
	}

	departmentSelect.on('change', () => {
		sectionSelect.value = '';
		fetchEmployeesAndResetSearch();
	});

	sectionSelect.on('change', (e) => {
		if (e.target.value !== '') {
			if (e.target.value === 'all') {
				sectionSelect.value = '';
			}
			departmentSelect.value = '';
		}
		fetchEmployeesAndResetSearch();
	});

	searchinput.on('keydown', (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			return false;
		} else {
			changeSearchInput();
			departmentSelect.value = '';
			sectionSelect.value = '';
			fetchEmployees();
		}
	});

	searchButton.on('click', (e) => {
		e.preventDefault();
		searchinput.value = '';
		changeSearchInput();
		fetchEmployees();
	});

	function fetchEmployeesAndResetSearch() {
		searchinput.value = '';
		fetchEmployees();
	}

	function changeSearchInput() {
		if (searchinput.value.length > 0) {
			searchButton.disabled = false;
			buttonIcon.classList = 'icon icon-pluss';
		} else {
			searchButton.disabled = true;
			buttonIcon.classList = 'icon icon-search';
		}
	}

	async function fetchEmployees(isReplace = true) {
		if (isReplace) {
			loadMoreButton.classList.remove('hidden');
			loadMoreButton.dataset['page'] = 1;
		}
		const page = parseInt(loadMoreButton.dataset['page'], 10);
		const formQuery = serializeForm(form);
		const query = `${formQuery}&page=${page}`;
		window.history.pushState('', '', `${basePath}?${query}`); // Updates url on current page.
		const url = `${form.action}?${query}`;
		const response = await fetch(url);
		const {
			listing: nextListing,
			hasMore,
			sections,
			selectedSection,
			departments,
			selectedDepartment,
		} = await response.json();
		if (!hasMore) {
			loadMoreButton.classList.add('hidden');
		}
		let listingContent;
		if (isReplace) {
			listingContent = nextListing;
		} else {
			listingContent = listingDomNode.innerHTML + nextListing;
		}
		listingDomNode.innerHTML = listingContent;
		deUglifySensitiveStrings(allEmployees);

		sectionsDomNode.innerHTML = sections;
		departmentsDomNode.innerHTML = departments;

		const newUrl = `department=${selectedDepartment}&section=${selectedSection}&page=${page}`;
		window.history.pushState('', '', `${basePath}?${newUrl}`); // Updates current url with new url
	}
}

import createUrlObject from './util/url-helper';

export default function initializeAccordions(selector) {
	const { basePath } = createUrlObject();
	const accordions = [...document.querySelectorAll(selector)];
	accordions.forEach(setupAccordion);

	function setupAccordion(accordionDom) {
		const listItems = [...accordionDom.querySelectorAll(':scope > .list-item, :scope > ul > .list-item')];
		listItems.forEach((accordionElement) => {
			const button = accordionElement.querySelector(':scope  > button.list-item__expand-button');
			const accordionContent = [...accordionElement.querySelectorAll(':scope > .list-item__content')];
			const [content] = accordionContent;
			const [expandContent] = content.querySelectorAll('div.grid');
			const contentHeight = expandContent?.offsetHeight + 32;

			const { dataset } = button;
			const { step } = dataset;

			button.addEventListener('click', (e) => {
				e.preventDefault();
				const isOpen = accordionElement.classList.contains('open');

				// If the element we clicked is allready open, we need to close it.
				if (isOpen) {
					accordionElement.classList.remove('open');
					const [content] = accordionElement.querySelectorAll(':scope  > .list-item__content');
					const [firstButton] = accordionElement.querySelectorAll(':scope  > .list-item__expand-button');
					content.removeAttribute('style');
					content.style.height = "0";
					if (firstButton) {
						accordionElement.setAttribute('aria-expanded', false);
					}
					content.setAttribute('aria-hidden', true);
					content.style.height = contentHeight + 'px';
					setTimeout(() => content.style.height = '0', 1);
					accordionElement.classList.remove('open');
					if (firstButton.id === button.id && step) {
						window.history.replaceState('', '', `${basePath}`);
					}
				}

				if (!isOpen) {
					accordionElement.setAttribute('aria-expanded', true);
					if (step) {
						// Updates url on current page.
						window.history.replaceState('', '', `${basePath}?openStep=${step}`);
					}

					accordionElement.classList.add('open');
					const [firstButton] = accordionElement.querySelectorAll(':scope  > .list-item__expand-button');
					if (firstButton) {
						firstButton.setAttribute('aria-expanded', true);
					}

					const [content] = accordionElement.querySelectorAll(':scope > .list-item__content');
					if (content) {
						content.removeAttribute('style');
						content.style.height = contentHeight + 'px';
						setTimeout(() => {
							content.style.height = 'auto';
							content.style.position = 'initial';
						}, 200);
						content.setAttribute('aria-hidden', false);
					}
				}
			});
		});
	}
}



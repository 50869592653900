const getNodeById = document.getElementById.bind(document);

const menuButtons = document.$('.button--header');
const headerLinks = getNodeById('header-navigation__links');
const body = getNodeById('js-body');
const mainElements = document.$('footer, main, button.to-top-button');
const [ header ] = document.$('header');

export default function initializeHeaderButtons() {
	const headerMenu = getNodeById('header-menu');
	const headerSearch = getNodeById('header-search');
	const [searchinput = null] = headerSearch.$('input');
	const headerLanguage = getNodeById('header-language');
	let openMenu = null;

	const lastMenuElement = getNodeById('last-menu-element');
	const searchButton = getNodeById('search-button');
	const closeButton = getNodeById('header-close-button');
	let lastFocus = null;
	
	const rootTab = getNodeById('root-tab');
	const administrationTab = getNodeById('administration-tab');

	body.classList.contains('is-administration') ? 
		setActiveTab(administrationTab, rootTab) : setActiveTab(rootTab, administrationTab);

	window.addEventListener('keyup', (e) => {
		if (e.key === 'Tab') {
			if (headerMenu.classList.contains('active')) {
				if(lastMenuElement === lastFocus){
					closeButton.focus();
				}
				lastFocus = document.activeElement;
			}
			if (headerSearch.classList.contains('active')) {
				if (searchButton === lastFocus){
					closeButton.focus();
				}
				lastFocus = document.activeElement;
			}
		}
		if (e.key === 'Escape') {
			if(openMenu) {
				closeMenu(openMenu);
				openMenu = null;
			}
		}
	});
	
	menuButtons.forEach(menuButton => menuButton.addEventListener('click', () => {

		if (menuButton.id.includes('search')) {
			triggerExpandAnimation(headerSearch);
			openMenu = headerSearch;
			body.classList.add('open-dialog');
			hideMenuButtons();
			searchinput.focus();
			headerSearch.scrollTop = 0;
		}

		if (menuButton.id.includes('menu')) {
			triggerExpandAnimation(headerMenu);
			openMenu = headerMenu;
			body.classList.add('open-dialog');
			hideMenuButtons();
			headerMenu.scrollTop = 0;

		}
		
		if (menuButton.id.includes('language')) {
			triggerExpandAnimation(headerLanguage);
			openMenu = headerLanguage;
			body.classList.add('open-dialog');
			hideMenuButtons();
			headerMenu.scrollTop = 0;

		}

		if (menuButton.id.includes('close')) {
			closeMenu(openMenu);
			openMenu = null;
		}
	}));
}

function closeMenu(openMenu) {
	triggerHideAnimation(openMenu);
	body.classList.remove('open-dialog');
	showMenuButtons();
}

function setActiveTab(activeTab, nonActvieTab) {
	activeTab.classList.add('active');
	nonActvieTab.classList.remove('active');
}

function triggerExpandAnimation(expandElement) {
	const [expandContent = null] = expandElement.$('div.header-content');
	const contentHeight = expandContent.offsetHeight;
	const windowHeight = window.innerHeight + 1 - header.offsetHeight;
	expandElement.classList.add('active');
	expandElement.style.height = window.innerHeight + 1 - header.offsetHeight + 'px';
	if (contentHeight < windowHeight) {
		expandContent.style.height = '100%';
	}
	setTimeout(() => expandContent.style.opacity = '1', 300);
}

function triggerHideAnimation(expandElement) {
	const [expandContent = null] = expandElement.$('div.header-content');
	expandElement.classList.remove('active');
	expandElement.style.height = window.innerHeight + 1 - header.offsetHeight + 'px';
	setTimeout(() => {
		expandElement.style.height = '0';
		expandContent.style.height = 'auto';
		expandContent.style.opacity = '0';
	}, 10);
}

function hideMenuButtons() {
	mainElements.forEach(element => element.setAttribute('hidden', true));
	menuButtons.forEach(menuButton => {
		menuButton.classList.add('hidden');
		if (menuButton.id.includes('close')) {
			menuButton.classList.remove('hidden');
		}
	});
	headerLinks.classList.add('hide');
}

function showMenuButtons() {
	mainElements.forEach(element => element.removeAttribute('hidden'));
	menuButtons.forEach(menuButton => {
		menuButton.classList.remove('hidden');
		if (menuButton.id.includes('close')) {
			menuButton.classList.add('hidden');
		}
	});
	headerLinks.classList.remove('hide');
}

const { $ } = window;

export default function initializeTabButtons() {
    const [root] = $('.pictures-and-profiles');
    if (root) {
        
        const tabButtons = $('.tab-button');
        const tabs = $('.tab');
        
        const [firstButton] = $('.tab-button');
        const [firstTab] = $('.tab');
        firstButton.classList.add('active');
        firstTab.classList.add('active');

        tabButtons.forEach((button) => {
            button.addEventListener('click', () => {
                const isActive = button.classList.contains('actvie');
                
                if (!isActive) {
                    tabButtons.forEach((tabButton) => {
                        tabButton.classList.remove('active');
                    });
                    tabs.forEach((tab) => {
                        tab.classList.remove('active');
                    });

					button.classList.add('active');
                    getNodeById(button.dataset.name).classList.add('active');
					
				}                
            });
		});
    }
}


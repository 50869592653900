export default function initializeStoreSjekkbokser() {
	const sjekkbokser = [...document.querySelectorAll('.checkbox')];
	sjekkbokser.forEach((sjekkboks) =>
		sjekkboks.on('change', function () {
			if (sjekkboks.checked) {
				sjekkboks.parentElement.classList.add('checked');
			} else {
				sjekkboks.parentElement.classList.remove('checked');
			}
		})
	);
}

import 'intersection-observer';

export default function initializeAnchorLinks() {
    
    const root = document.querySelector('#js-article');
    let notFirst = false;
    let activeLinkContainer = null;

    if (root) {

        const anchorLinks = [...document.querySelectorAll('.anchor-link')];
        let titles = [...root.querySelectorAll('h2, h3')];

        titles = titles.filter( heading => {
            return !heading.parentElement.classList.contains('wrapper') && heading.id != '';
        });
        const linkContainers = [...root.querySelectorAll('.link-container')];
        const [firstLinkContainer = null] = linkContainers;
        const [firstAnchor = null] = anchorLinks;

        if(firstAnchor && firstLinkContainer) {
            firstAnchor.classList.add('active');
            firstAnchor.parentElement.classList.add('active');
            firstLinkContainer.classList.add('active');
        }

        const options = {
            rootMargin: '-10px',
        };
    
        let callback = (entries) => {
            if (notFirst){
                entries.forEach((entry) => {
                    if (entry.isIntersecting && entry.boundingClientRect.top < 500) {
                        setActiveLinkOnScroll(anchorLinks, entry, linkContainers);
                    }
                    if (!entry.isIntersecting && entry.boundingClientRect.top < 0) {
                        setActiveLinkOnScroll(anchorLinks, entry, linkContainers);
                    }
                    
                });
            } else {
                notFirst = true;
            }
        };

        const observer = new IntersectionObserver(callback, options);
        
        titles.forEach(title => {
            observer.observe(title);
        });
        
        
        anchorLinks.forEach(link => link.addEventListener('click', () => {
            onAnchorLinkClick()(link, anchorLinks, observer, titles, linkContainers);
        }));
    }

    function onAnchorLinkClick() {
        return (link, anchorLinks, observer, titles, linkContainers) => {
            observer.disconnect();

            let scrollTimeoutId;

            window.addEventListener('scroll', () => {
                window.clearTimeout(scrollTimeoutId);

                scrollTimeoutId = setTimeout(() => {
                    notFirst = false;

                    titles.forEach(title => observer.observe(title));

                }, 100);
            }, false);


            const isActive = link.classList.contains('active');

            
            if (!isActive) {
                anchorLinks.forEach(link => {
                    if (link.parentElement.classList.contains('active')) {
                        link.parentElement.classList.remove('active');
                    }
                    link.classList.remove('active');
                });
                link.classList.add('active');
                link.parentElement.classList.add('active');

                setActiveLinkContainer(link, linkContainers);
            }
        };
    }

    function setActiveLinkOnScroll(anchorLinks, entry, linkContainers) {
        anchorLinks.forEach(link => {
    
            const isActive = link.classList.contains('active');
            
            if (!isActive && new RegExp("#" + entry.target.id).test(decodeURI(link.href))) {
                anchorLinks.forEach(link => {
                    if (link.parentElement.classList.contains('active')) {
                        link.parentElement.classList.remove('active');
                    }
                    link.classList.remove('active');
                });

                setTimeout(() => {
                    link.classList.add('active');
                    link.parentElement.classList.add('active');
                    setActiveLinkContainer(link, linkContainers);
                }, 0.1);
            }
        });
    }
    
    function setActiveLinkContainer(link, linkContainers) {
        linkContainers.forEach(container => {
            const subLinkList = container.querySelector('.sub-titles');
            subLinkList.setAttribute('aria-hidden', true);
            if (container.dataset.index == link.dataset.index) {
                if (activeLinkContainer !== null) {
                    activeLinkContainer.classList.remove('active');
                }
                subLinkList.setAttribute('aria-hidden', false);
                activeLinkContainer = container;
                activeLinkContainer.classList.add('active');
            }
        });
    }
}

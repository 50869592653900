(async () => {
	const listings = $('.difi-report-listing');
	if (!listings || listings.length == 0) {
		return;
	}

	listings.forEach(listing => {
		const buttons = [...listing.$('button.difi-report-listing__button')];
		buttons.forEach((buttonDomNode) => {
			buttonDomNode.addEventListener('click', () => {
				buttonDomNode.classList.toggle('is-open');
			});
		});
	});

	const [ form ] = $('#searchForm'),
		[ spinner ] = $('.lds-ellipsis'),
		[ button ] = $('.report-options__searchbutton a'),
		[ aarInput ] = $('#report-year'),
		[ fylkeInput ] = $('#report-fylkenr'),
		[ kommuneInput ] = $('#report-kommunenr'),
		[ mottakerInput ] = $('#report-search-input');

	button.addEventListener('click', reload);
	aarInput.on('change', aarChanged);
	fylkeInput.on('change', fylkeChanged);
	kommuneInput.on('change', reload);

	function aarChanged(){
		fylkeInput.value = '';
		kommuneInput.value = '';
		reload();
	}

	function fylkeChanged(){
		kommuneInput.value = '';
		reload();
	}

	async function reload() {
		spinner.classList.remove('hidden');
		button.classList.add('hidden');
		form.submit();
		aarInput.setAttribute("disabled", true);
		fylkeInput.setAttribute("disabled", true);
		kommuneInput.setAttribute("disabled", true);
		mottakerInput.setAttribute("disabled", true);
	}
})();

export default function initializeSearchResults() {

    const moreButton = document.getElementById("more-button");
    const formNode = document.querySelector('form.search-form');

    if(moreButton){
        moreButton.addEventListener('click', () => {
            const { dataset = {} } = formNode;
            const { searchquery = '', pagenumber, serviceurl } = dataset;
            const pageNum = parseInt(pagenumber, 10);

            const paramString = 'q=' + searchquery.split(" ").join("+") + "&page=" + pageNum;
            fetchSearchResults(serviceurl, paramString);

            formNode.dataset.pagenumber = pageNum + 1;
        });
    }

    function fetchSearchResults(productListFormUrl, paramString) {

        fetch(productListFormUrl + '?' + paramString, getRequest())
            .then(res => res.json())
            .then((data) => {
                const { results = '', moreResults } = data;
                addSearchResultsContent(results, moreResults);
            });

    }

    function getRequest(request) {
        return {
            method: 'GET',
            credentials: 'same-origin',
            ...request
        };
    }

    function addSearchResultsContent(results, moreResults) {
        document.querySelector('.search-part__results').innerHTML += results;

        if(!moreResults) {
            moreButton.classList.add('hidden');
        }
    }
}

import createUrlObject from './util/url-helper';
import debounced from './util/debounced';
import { serializeForm } from './util/form';

const DEBOUNCED_WAIT = 400;

const page = getNodeById('industry-arrangements');
if (page) {
	initialize();
}

function initialize() {
	const [ form ] = page.$('form');
	const { basePath } = createUrlObject(window.location.href);
	const listingDomNode = getNodeById('industry-arrangements-listing');
	const inputs = page.$('form input');
	inputs.on('change', debounced(doFilterRequest, DEBOUNCED_WAIT));

	async function doFilterRequest() {
			const resultDomNode = getNodeById('grouped-arrangement-listing__results');
			resultDomNode.classList.add('opacity');
			const query = serializeForm(form);
			window.history.replaceState('', '', `${basePath}?${query}`); // Updates url on current page.
			const url = `${form.action}?${query}`;
			const response = await fetch(url);
			listingDomNode.innerHTML = await response.text();
	}
}

import { serializeForm } from './util/form';
import createUrlObject from './util/url-helper';

export default function initalizeCollectionPages() {
	const allCollectionPages = getNodeById('all-collection-pages');
	if (!allCollectionPages) {
		return;
	}

	const [form] = allCollectionPages.$('form');
	const listingDomNode = getNodeById('all-collection-pages-listing');
	const loadMoreButton = getNodeById('load-more-collection-pages');
	const searchinput = getNodeById('all-collection-pages-options-input');
	const searchButton = getNodeById('remove-button');
	const buttonIcon = getNodeById('button-icon');

	const { basePath } = createUrlObject(window.location.href);

	changeSearchInput();

	loadMoreButton.on('click', getLoadMoreClickHandler());

	function getLoadMoreClickHandler() {
		return (e) => {
			e.preventDefault();
			const page = parseInt(loadMoreButton.dataset['page'], 10);
			loadMoreButton.dataset['page'] = page + 1;
			fetchCollectionPages(false);
			return false;
		};
	}

	allCollectionPages.$('select').on('change', fetchCollectionPages);
	
	searchinput.on('keydown', (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			return false;
		} else {
			changeSearchInput();
			fetchCollectionPages();
		}
	});
	searchButton.on('click', (e) => {
		e.preventDefault();
		searchinput.value = '';
		changeSearchInput();
		fetchCollectionPages();
	});

	function changeSearchInput() {
		if (searchinput.value.length > 0) {
			searchButton.disabled = false;
			buttonIcon.classList = "icon icon-pluss";
		} else {
			searchButton.disabled = true;
			buttonIcon.classList = "icon icon-search";
		}
	}

	async function fetchCollectionPages(isReplace = true) {
		if (isReplace) {
			loadMoreButton.classList.remove('hidden');
			loadMoreButton.dataset['page'] = 1;
		}
		const page = parseInt(loadMoreButton.dataset['page'], 10);
		const formQuery = serializeForm(form);
		const query = `${formQuery}&page=${page}`;
		window.history.pushState('', '', `${basePath}?${query}`); // Updates url on current page.
		const url = `${form.action}?${query}`;
		const response = await fetch(url);
		const { listing: nextListing, hasMore } = await response.json();
		if (!hasMore) {
			loadMoreButton.classList.add('hidden');
		}
		let listingContent;
		if (isReplace) {
			listingContent = nextListing;
		} else {
			listingContent = listingDomNode.innerHTML + nextListing;
		}
		listingDomNode.innerHTML = listingContent;
	}
}
import { serializeForm } from './util/form';
import createUrlObject from './util/url-helper';

export default function initalizeAlleRapporter() {
	const alleRapporter = getNodeById('alle-rapporter-page');

	if (!alleRapporter) {
		return;
	}
	const [form] = alleRapporter.$('form');
	const listingDomNode = getNodeById('alle-rapporter-listing');
	const loadMoreButton = getNodeById('load-flere-rapporter');

	const searchInputRapportNummer = getNodeById('rapport-options-input-rapportnummer');
	const searchButtonRapportNummer = getNodeById('remove-button-rapportnummer');
	const buttonIconRapportNummer = getNodeById('button-icon-rapportnummer');

	const searchInputName = getNodeById('rapport-options-input-name');
	const searchButtonName = getNodeById('remove-button-name');
	const buttonIconName = getNodeById('button-icon-name');

	const { basePath } = createUrlObject(window.location.href);

	searchInputRapportNummer.on('keydown', (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			changeSearchInput(searchInputRapportNummer, searchButtonRapportNummer, buttonIconRapportNummer);
			fetchRapport();
		} else {
			changeSearchInput(searchInputRapportNummer, searchButtonRapportNummer, buttonIconRapportNummer);
			fetchRapport();
		}
	});

	searchButtonRapportNummer.on('click', (e) => {
		e.preventDefault();
		searchInputRapportNummer.value = '';
		changeSearchInput(searchInputRapportNummer, searchButtonRapportNummer, buttonIconRapportNummer);
		fetchRapport();
	});

	searchInputName.on('keydown', (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			changeSearchInput(searchInputName, searchButtonName, buttonIconName);
			fetchRapport();
		} else {
			changeSearchInput(searchInputName, searchButtonName, buttonIconName);
			fetchRapport();
		}
	});

	searchButtonName.on('click', (e) => {
		e.preventDefault();
		searchInputName.value = '';
		changeSearchInput(searchInputName, searchButtonName, buttonIconName);
		fetchRapport();
	});

	loadMoreButton.on('click', (e) => {
		e.preventDefault();
		const page = parseInt(loadMoreButton.dataset['page'], 10);
		loadMoreButton.dataset['page'] = page + 1;
		fetchRapport(false);
	});
	alleRapporter.$('select').on('change', fetchRapport);

	function changeSearchInput(input, button, icon) {
		if (input.value.length > 0) {
			button.disabled = false;
			icon.classList = "icon icon-pluss";
		} else {
			button.disabled = true;
			icon.classList = "icon icon-search";
		}
	}


	async function fetchRapport(isReplace = true) {
		if(isReplace) {
			loadMoreButton.classList.remove('hidden');
			loadMoreButton.dataset['lastMonth'] = '';
			loadMoreButton.dataset['page'] = 1;
		}
		const { lastMonth: currentLastMonth } = loadMoreButton.dataset;
		const page = parseInt(loadMoreButton.dataset['page'], 10);
		const formQuery = serializeForm(form);
		const query = `${formQuery}&page=${page}&lastMonth=${currentLastMonth}`;
		window.history.pushState('', '', `${basePath}?${query}`); // Updates url on current page.
		const url = `${form.action}?${query}`;
		const response = await fetch(url);
		const { listing: nextListing, lastMonth, hasMore } = await response.json();
		loadMoreButton.dataset['lastMonth'] = lastMonth;

		if(!hasMore){
			loadMoreButton.classList.add('hidden');
		}

		let listingContent;
		if (isReplace) {
			listingContent = nextListing;
		} else {
			listingContent = listingDomNode.innerHTML + nextListing;
		}
		listingDomNode.innerHTML = listingContent;
	}

}

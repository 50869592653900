export default function initializeEvents() {

	const dayButtons = [...document.querySelectorAll('.talk-buttons')];
	dayButtons.forEach( element => element.addEventListener('click', (event) => toggleActive(element, event)));
	dayButtons.forEach( element => element.addEventListener('keydown', (event) => {
		if (event.keyCode === 13) {
			return toggleActive(element, event);
		}
	}));

	function toggleActive(e, event) {
		const groupedButtons = e.parentElement.parentElement.querySelectorAll('.talk-buttons');
		groupedButtons.forEach( element => element.classList.remove("active"));
		e.classList.add("active");

		const listedTalks = e.closest('.schedule-tab').parentElement.querySelectorAll('.list-item');

		listedTalks.forEach( element => {
			if (element.getAttribute('topic') === e.getAttribute('topic')) {
				element.classList.remove("hidden");
				element.classList.add("visible");
			} else {
				element.classList.remove("visible");
				element.classList.add("hidden");
			}
		});
		event.preventDefault();
	}
}

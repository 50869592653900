/* Adding aliases for attaching event listeners to all dom elements. Also shortens querySelectorAll to just $ */
window.$ = document.querySelectorAll.bind(document);
window.getNodeById = document.getElementById.bind(document);

Node.prototype.on = window.on = function (name, fn) {
	this.addEventListener(name, fn);
};

Node.prototype.$ = function(selectors) {
	return this.querySelectorAll(selectors);
};

NodeList.prototype.__proto__ = Array.prototype;
NodeList.prototype.on = NodeList.prototype.addEventListener = createEventListeners;

function createEventListeners(name, fn) {
	this.forEach((elem) => elem.on(name, fn));
}

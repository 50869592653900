import createUrlObject from './util/url-helper';
import debounced from './util/debounced';
import { serializeForm } from './util/form';

const getNodeById = document.getElementById.bind(document);

const DEBOUNCED_WAIT = 400;

const page = getNodeById('document-content');
if (page) {
	initializeDocumentList();
}

function initializeDocumentList() {
	const form = getNodeById('document-index');

	const debouncedSearchDocumentIndexFn = debounced(() => searchDocumentIndex(), DEBOUNCED_WAIT);

	form.on('submit', getSubmitHandler());

	function getSubmitHandler() {
		return (event) => {
			event.preventDefault();
			debouncedSearchDocumentIndexFn();
			return false;
		};
	}

	function searchDocumentIndex() {
		runSearchQuery()
			.then((searchResult) => {
				renderResults(searchResult);
			});
	}

	function renderResults(searchResult) {
		page.$('a.document-article-link').forEach((link) => {
			link.href = link.href.substring(link.href.lastIndexOf('?'));
			const [hitsCount = null] = link.$('div.hits-count-wrapper');
			hitsCount.classList.add('hidden');
		});

		const { q, totalHits, searchResults } = searchResult;
		getNodeById('total-hits-wrapper').classList.remove('hidden');
		getNodeById('total-hits').innerHTML = totalHits;
		getNodeById('search-query').innerHTML = q;

		searchResults.forEach(({ id, path, phraseCount }) => {
			if(phraseCount > 0) {
				const documentArticleNode = getNodeById(`${id}`);
				documentArticleNode.href = `${path}`;
				const [hitsCountWrapper = null] = documentArticleNode.$('div.hits-count-wrapper');
				hitsCountWrapper.classList.remove('hidden');
				const [hitsCount = null] = documentArticleNode.$('span.hits-count');
				hitsCount.innerHTML = phraseCount;
			}
		});
	}

	async function runSearchQuery() {
		const { basePath } = createUrlObject(window.location.href);

		const formQuery = serializeForm(form);
		const query = formQuery ? `${formQuery}` : ``;
		window.history.replaceState('', '', `${basePath}?${query}`); // Updates url on current page.
		const url = `${form.action}?${query}`;
		const response = await fetch(url);
		return response.json();
	}
}

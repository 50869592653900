export default function deUglifySensitiveStrings(parentNode) {
	processDom(parentNode, 'email-anchor',
		(domNode) =>
			deuglifyAttribute(domNode, ATTRIBUTE_TYPE.EMAIL)
	);
	processDom(parentNode, 'phone-anchor',
		(domNode) =>
			deuglifyAttribute(domNode, ATTRIBUTE_TYPE.PHONE)
	);
	processDom(parentNode, 'phone-value',
		(domNode) =>
			domNode.innerHTML = deUglifyString(domNode.innerHTML)
	);
}

function processDom(parentNode, className, actionFn) {
	return [...parentNode.$('.' + className)].forEach((domNode) => {
		actionFn(domNode);
		domNode.classList.remove(className);
	});
}

function deuglifyAttribute(domNode, type = 0) {
	const prefix = type === ATTRIBUTE_TYPE.PHONE ? 'tel:' : 'mailto:';
	const hrefValue = domNode.getAttribute('href').replace(prefix, '');
	const newValue = `${prefix}${deUglifyString(hrefValue)}`;
	domNode.setAttribute('href', newValue);
}

function deUglifyString(str) {
	if (!str || typeof str !== 'string') return str;
	return str
		.split('_')
		.map((code) => String.fromCharCode(parseInt(code, 10)))
		.join('');
}

const ATTRIBUTE_TYPE = {
	PHONE: 0,
	EMAIL: 1
};
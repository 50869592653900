export default function initializeToTop() {
    const toTopButton = document.getElementById('to-top');

    window.onscroll = showButtonToTop;
    
    toTopButton.addEventListener('click', () => {
        document.body.scrollTop = 0; //Safari
        document.documentElement.scrollTop = 0; //Chrome, Firefox, IE, Opera
    });

    function showButtonToTop() {
        if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
            toTopButton.classList.add('js-showToTopButton');
        } else {
            toTopButton.classList.remove('js-showToTopButton');
        }
    }
}


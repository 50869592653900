export default function initalizeKunngjoringAndArrangement() {
	const arrangement = getNodeById('js-applicationprocess');
	const arrangementSingleStep = getNodeById('js-applicationprocess-single-step');
	const kunngjoring = getNodeById('js-kunngjoring');

	if (!arrangement && !arrangementSingleStep && !kunngjoring) {
		return;
	}

	const formDropdownButton = getNodeById('forms-dropdown-button');
	const formDropdown = getNodeById('forms-dropdown');

	document.on('click', ({ target }) => {
		if ([...target.classList].includes('paper-form-link')) {
			const [checkmarkIcon = null] = target.$('span.icon-checkmark');
			checkmarkIcon.classList.remove('hidden');
		}
	});

	if (formDropdownButton !== null) {
		formDropdownButton.addEventListener('click', () => {
			const isOpen = formDropdownButton.classList.contains('open');

			if(isOpen) {
				formDropdownButton.classList.remove('open');
				formDropdown.classList.add('hidden');
			} else {
				formDropdownButton.classList.add('open');
				formDropdown.classList.remove('hidden');
			}
		});
	}
}

export default function createUrlObject(url = window.location.href) {
	const [ basePath, searchQuery ] = url.split('?');

	if (/\?/.test(url) === false || !searchQuery) {
		return { basePath, keyMap: [] };
	}

	const keyValues = searchQuery.split('&');
	const keyMap = {};

	keyValues.forEach((keyValue) => {
		const [ key, value = null ] = keyValue.split('=');
		keyMap[key] = parseInt(value);
	});
	return { basePath, keyMap };
}

export function createSearchQuery(keyMap) {
	const keys = Object.keys(keyMap);
	return keys.reduce((memo, key) =>
		`${memo}${memo !== '?' ? '&' : ''}${key}=${keyMap[key]}` , keys.length !== 0 ? '?' : '');
}

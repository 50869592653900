export default function initializeNyhetsbrev() {

	const nyhetsbrev = getNodeById('js-nyhetsbrev-abonnement');
	const SPACE_KEY_CODE = 32;
	const ENTER_KEY_CODE = 13;

	if (!nyhetsbrev) {
		return;
	}

	const tab = [...document.querySelectorAll('.tabs__link')];
	tab.forEach(element => element.addEventListener('click', (event) => toggleActive(element, event)));
	tab.forEach(element => element.addEventListener('keydown', (event) => {
		if (event.keyCode === SPACE_KEY_CODE) {
			return toggleActive(element, event);
		}
	}));

	function toggleActive(e, event) {
		const tabs = e.parentElement.parentElement.querySelectorAll('.tabs__link');
		tabs.forEach( element => element.classList.remove("active"));
		e.classList.add("active");

		const meny = e.closest('.tabs-container').parentElement.querySelectorAll('.valg-fields-item');

		meny.forEach(element => {
			if (element.getAttribute('key') === e.getAttribute('key')) {
				element.classList.remove("hidden");
				element.classList.add("visible");
			} else {
				element.classList.remove("visible");
				element.classList.add("hidden");
			}
		});
		event.preventDefault();
	}

	const firstLevelCheckboxes = document.querySelectorAll('input.option');

	firstLevelCheckboxes.forEach(checkbox => {
		const nestedCheckboxes =
			checkbox.parentElement.parentElement.querySelectorAll('input.subOption');

		nestedCheckboxes.forEach( element =>  {
			element.onclick = () => {
				const checkedCount =
					checkbox.parentElement.parentElement.querySelectorAll('input.subOption:checked').length;
				checkbox.indeterminate = checkedCount > 0 && checkedCount < nestedCheckboxes.length;
				checkbox.checked = checkedCount === nestedCheckboxes.length;
			};
		});

		checkbox.onclick = function() {
			nestedCheckboxes.forEach(element => element.checked = this.checked);
		};
	});

	const masterTemaCheckbox = document.querySelector('input.checkbox.velg-alle');
	const temaCheckboxes=
		masterTemaCheckbox.parentElement.parentElement.querySelectorAll('input.checkbox');

	temaCheckboxes.forEach( element => {
		element.onclick = () => {
			const checkedCount = masterTemaCheckbox.parentElement.parentElement
				.querySelectorAll('input.checkbox:checked').length;
			masterTemaCheckbox.checked = checkedCount > 1 ;
		};
	});

	masterTemaCheckbox.onclick = function() {
		temaCheckboxes.forEach(element => element.checked = this.checked);
	};

	const masterForvaltningTemaCheckbox = document.querySelector('input.checkbox.forvaltning');
	const forvaltningTtemaCheckboxes=
		masterForvaltningTemaCheckbox.parentElement.parentElement.querySelectorAll('input.checkbox');

	forvaltningTtemaCheckboxes.forEach(element => {
		element.onclick = () => {
			const checkedCount = masterForvaltningTemaCheckbox.parentElement.parentElement
					.querySelectorAll('input.checkbox:checked').length;
			masterForvaltningTemaCheckbox.checked = checkedCount > 1 ;
		};
	});

	masterForvaltningTemaCheckbox.onclick = function() {
		forvaltningTtemaCheckboxes.forEach(element => element.checked = this.checked);
	};

	const saveButton = document.querySelector('.save-button');
	const lowerSaveButton = document.querySelector('.button.save');
	const form = document.getElementById('nyhetsbrev-abonnement');
	const varsel = document.querySelector('.validering');

	function validateInput(event) {
		varsel.classList.remove("visible");
		varsel.classList.add("hidden");
		const formData = new FormData(form);
		const underTema = formData.getAll('subTema');
		const tema = formData.getAll('tema');
		const type = formData.getAll('type');
		const forvaltningTema = formData.getAll('forvaltningTema');
		const forvaltningType = formData.getAll('forvaltningType');

		if (((underTema.length !== 0 || tema.length !== 0) && type.length === 0) ||
			((underTema.length === 0 && tema.length === 0) && type.length !== 0)) {
			varsel.classList.remove("hidden");
			varsel.classList.add("visible");
			event.preventDefault();
		}

		if ((forvaltningTema.length === 0 && forvaltningType.length !== 0) ||
			(forvaltningTema.length !== 0 && forvaltningType.length === 0)) {
			varsel.classList.remove("hidden");
			varsel.classList.add("visible");
			event.preventDefault();
		}

		if (underTema.length === 0 && type.length === 0
			&& forvaltningTema.length === 0 && forvaltningType.length === 0 ) {
			varsel.classList.remove("hidden");
			varsel.classList.add("visible");
			event.preventDefault();
		}
	}

	saveButton.addEventListener('click', (event) => validateInput(event));
	lowerSaveButton.addEventListener('click', (event) => validateInput(event));
	saveButton.addEventListener('keydown', (event) => {
		if (event.keyCode === ENTER_KEY_CODE) {
			return validateInput(event);
		}
	});
	lowerSaveButton.addEventListener('keydown', (event) => {
		if (event.keyCode === ENTER_KEY_CODE) {
			return validateInput(event);
		}
	});
}

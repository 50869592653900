import createUrlObject from './util/url-helper';
import debounced from './util/debounced';
import { serializeForm } from './util/form';

const { $ } = window;
const getNodeById = document.getElementById.bind(document);

const DEBOUNCED_WAIT = 400;
const OPEN_DIALOG_CLASS = 'open-dialog';

const page = getNodeById('filterlist-search');
if (page) {
	initializeFilterlist();
}

function initializeFilterlist() {
	const [ body ] = $('body');
	const form = getNodeById('filterlist-filters');
	const resultSection = getNodeById('filterlist-list-section');
	const loadMoreButton = getNodeById('load-more-filterlist');
	const filterBoxes = page.$('input.filterlist-types__input');
	const totalCountNode = getNodeById('filterlist-total');
	const filterButton = getNodeById('filterlist-search-open-filter-dialog');
	const closeFilterButton = getNodeById('filterlist-filter-close');
	const [ submitFilterButton ] = $('button.js-activate-filters');
	const [ filterModal ] = $('.filterlist-filter-mobile');

	const debouncedSearchFilterlistsFn = debounced(() => searchFilterlist(), DEBOUNCED_WAIT);

	form.on('submit', getSubmitHandler());
	loadMoreButton.on('click', getLoadMoreClickHandler());
	filterBoxes.on('change', debouncedSearchFilterlistsFn);
	filterButton.on('click', toggleFilterDialog);
	closeFilterButton.on('click', toggleFilterDialog);
	submitFilterButton.on('click', toggleFilterDialog);

	filterBoxes.on('change', ({ target }) => {
		if(target.name == 'industries') {
			const categoryContainer = getNodeById(`industy-categories-${target.value}`);
			if(categoryContainer) {
				if(!target.checked) {
					filterBoxes.filter(
						filterBox => filterBox.dataset['industry'] == target.id && filterBox.checked == true
					)
					.forEach((filterBox => filterBox.checked = false));
					categoryContainer.classList.add('hidden');
				}else {
					categoryContainer.classList.remove('hidden');
				}
			}
		}
	});

	function toggleFilterDialog() {
		if (body.classList.contains(OPEN_DIALOG_CLASS)) {
			body.classList.remove(OPEN_DIALOG_CLASS);
			filterModal.classList.remove('open');
		} else {
			filterModal.classList.add('open');
			body.classList.add(OPEN_DIALOG_CLASS);
		}
	}

	function getSubmitHandler() {
		return (event) => {
			event.preventDefault();
			debouncedSearchFilterlistsFn();
			return false;
		};
	}

	function getLoadMoreClickHandler() {
		return (e) => {
			e.preventDefault();
			const page = parseInt(loadMoreButton.dataset['page'], 10);
			loadMoreButton.dataset['page'] = page + 1;
			runSearchQuery()
				.then((resultList) =>
					renderResults(resultList, true));

			return false;
		};
	}

	function searchFilterlist() {
		resultSection.classList.add('opacity-animation');
		loadMoreButton.dataset['page'] = 1;
		runSearchQuery()
			.then((searchResult) => {
				renderResults(searchResult);
				resultSection.onanimationend = () => {
					resultSection.classList.remove('opacity-animation');
				};
			});
	}

	function renderResults(searchResult, append = false) {
		const {
			resultList,
			start,
			count,
			total,
			typeBuckets = [],
			industryBuckets = [],
			categoryBuckets = []
		} = searchResult;

		resultSection.innerHTML = `${append ? resultSection.innerHTML : ''}${resultList}`;
		totalCountNode.innerHTML = total;
		if (total > (start + count)) {
			if (loadMoreButton.classList.contains('hidden')) {
				loadMoreButton.classList.remove('hidden');
			}
		} else {
			if (!loadMoreButton.classList.contains('hidden')) {
				loadMoreButton.classList.add('hidden');
			}
		}

		typeBuckets.forEach(({ key: id, docCount }) => {
			const typeCountNode = getNodeById(`type-doccount-${id}`);
			if (typeCountNode) {
				typeCountNode.innerHTML = `(${docCount})`;
			}
		});

		industryBuckets.forEach(({ key: id, docCount }) => {
			const industryCountNode = getNodeById(`industry-doccount-${id}`);
			if (industryCountNode) {
				industryCountNode.innerHTML = `(${docCount})`;
			}
		});

		categoryBuckets.forEach(({ key: id, docCount }) => {
			const categoryCountNode = getNodeById(`category-doccount-${id}`);
			if (categoryCountNode) {
				categoryCountNode.innerHTML = `(${docCount})`;
			}
		});
	}

	async function runSearchQuery() {
		const { basePath } = createUrlObject(window.location.href);
		const page = parseInt(loadMoreButton.dataset['page'], 10);
		const formQuery = serializeForm(form);
		const query = formQuery ? `${formQuery}&page=${page}` : `page=${page}`;
		window.history.replaceState('', '', `${basePath}?${query}`); // Updates url on current page.

		const url = `${form.action}?${query}`;
		const response = await fetch(url);
		return response.json();
	}
}

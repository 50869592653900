
export function serializeForm(form) {
	const data = [];
	[...form.elements].forEach(({ name, value, disabled, type, checked }) => {
		if (!name || disabled || ['file', 'reset', 'submit', 'button'].indexOf(type) > -1) return;
		if (['checkbox', 'radio'].indexOf(type) > -1 && !checked) return;
		data.push(encodeURIComponent(name) + '=' + encodeURIComponent(value));
	});
	return data.join('&');
}

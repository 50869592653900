import './scripts/break-iframe';
import './scripts/util/dom-helpers';
import initializeAccordions from './scripts/list-accordion';
import initializeEvents from './scripts/event';
import initializeHeaderButtons from './scripts/header-buttons';
import initializeRadioButtons from './scripts/feedback';
import initializeAnchorLinks from './scripts/anchor-links';
import initializeSearchResults from './scripts/search-results';
import initializeToTop from './scripts/to-top-button';
import initializeTabButtons from './scripts/tab-buttons';
import initalizeNewsArchive from './scripts/news-archive';
import initalizeAllMessages from './scripts/all-messages';
import initalizeAllEmployees from './scripts/all-employees';
import initalizeArticle from './scripts/article';
import initalizeKunngjoringAndArrangement from './scripts/arrangement';
import initalizeCollectionPages from './scripts/all-collection-pages';
import stickybits from 'stickybits';
import './scripts/print-download';
import './scripts/filterlist';
import './scripts/deUglifyBoxes';
import './scripts/document-index';
import './scripts/industry-arrangement';
import './scripts/dates-overview';
import lazySizes from 'lazysizes';
import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/optimumx/ls.optimumx';
import 'element-qsa-scope';
import './scripts/difi-report';
import initalizeAlleRapporter from './scripts/rapport';
import initalizeProjectArchive from './scripts/project-archive';
import initalizeAlleKunngjoringer from './scripts/alle-kunngjoringer';
import initializeStoreSjekkbokser from './scripts/stor-sjekkboks';
import initializeNyhetsbrev from './scripts/nyhetsbrev-abonnement';
import initializeNyhetsbrevRegistrering from './scripts/newsletter-registration';

window.isInitialized = false;

(async () => {
	if (window.isInitialized) {
		return; // to prevent double-event registration on older ios versions
	}

	initalizeArticle();
	initializeEvents();
	initializeAccordions('.list-accordion');
	initializeHeaderButtons();
	initializeAnchorLinks();
	initializeToTop();
	initializeSearchResults();
	initializeRadioButtons();
	initializeTabButtons();
	initalizeNewsArchive();
	initalizeAlleRapporter();
	initalizeProjectArchive();
	initalizeAllEmployees();
	initalizeAllMessages();
	initalizeKunngjoringAndArrangement();
	initalizeCollectionPages();
	initalizeAlleKunngjoringer();
	initializeStoreSjekkbokser();
	initializeNyhetsbrev();
	initializeNyhetsbrevRegistrering();
	stickybits('.js-sticky');

	const lazySizesConfig = window.lazySizesConfig || {};
	lazySizesConfig.expand = 1000;
	lazySizes.init();

	window.isInitialized = true;
})();

export default function initializeNyhetsbrevRegistrering() {
	const nyhetsbrevRegistrering = getNodeById('js-nyhetsbrev-registrering');

	if (!nyhetsbrevRegistrering) {
		return;
	}

	const link = document.getElementById('email-link');
	const href = link.getAttribute('href');
	const input = document.getElementById('newsletter-email');
	const errorMessage = document.getElementById('error-message');

	input.addEventListener('keyup', function() {
		link.setAttribute('href', href + input.value);
	});

	link.addEventListener('click', (e) => {
		if (!isValidEmail()) {
			e.preventDefault();
		}
	});

	function isValidEmail() {
		const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		if (input.value.match(validEmailRegex)) {
			errorMessage.classList.remove("visible");
			errorMessage.classList.add('hidden');
			return true;
		}
		errorMessage.classList.remove("hidden");
		errorMessage.classList.add('visible');
		input.focus();
		return false;
	}
}

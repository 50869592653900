import { serializeForm } from './util/form';
import createUrlObject from './util/url-helper';

export default function initalizeAllMessages() {
	const allMessages = getNodeById('all-messages-page');
	
	if (!allMessages) {
		return;
	}
	const [form] = allMessages.$('form');
	const listingDomNode = getNodeById('all-messages-listing');
	const loadMoreButton = getNodeById('load-more-messages');

	const { basePath } = createUrlObject(window.location.href);

	loadMoreButton.on('click', getLoadMoreClickHandler());
	
	function getLoadMoreClickHandler() {
		return (e) => {
			e.preventDefault();
			const page = parseInt(loadMoreButton.dataset['page'], 10);
			loadMoreButton.dataset['page'] = page + 1;
			fetchMessages(false);
			return false;
		};
	}

	allMessages.$('select').on('change', fetchMessages);

	async function fetchMessages(isReplace = true) {
		if(isReplace) {
			loadMoreButton.classList.remove('hidden');
			loadMoreButton.dataset['lastMonth'] = '';
			loadMoreButton.dataset['page'] = 1;
		}
		const { lastMonth: currentLastMonth } = loadMoreButton.dataset;
		const page = parseInt(loadMoreButton.dataset['page'], 10);
		const formQuery = serializeForm(form);
		const query = `${formQuery}&page=${page}&lastMonth=${currentLastMonth}`;
		window.history.pushState('', '', `${basePath}?${query}`); // Updates url on current page.
		const url = `${form.action}?${query}`;
		const response = await fetch(url);
		const { listing: nextListing, lastMonth, hasMore } = await response.json();
		loadMoreButton.dataset['lastMonth'] = lastMonth;

		if(!hasMore){
			loadMoreButton.classList.add('hidden');
		}
		
		let listingContent;
		if (isReplace) {
			listingContent = nextListing;
		} else {
			listingContent = listingDomNode.innerHTML + nextListing;
		}
		listingDomNode.innerHTML = listingContent;
	}

}

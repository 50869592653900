import { serializeForm } from './util/form';
import createUrlObject from './util/url-helper';

export default function initalizeProjectArchive() {
	const projectArchive = getNodeById('project-archive-page');

	if (!projectArchive) {
		return;
	}
	const [form] = projectArchive.$('form');
	const listingDomNode = getNodeById('project-archive-listing');
	const loadMoreButton = getNodeById('load-more-projects');

	const searchInput = getNodeById('project-options-input');
	const searchButton = getNodeById('remove-button');
	const buttonIcon = getNodeById('button-icon');

	const { basePath } = createUrlObject(window.location.href);

	searchInput.on('keydown', (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			changeSearchInput(searchInput, searchButton, buttonIcon);
			fetchProjects();
		} else {
			changeSearchInput(searchInput, searchButton, buttonIcon);
			fetchProjects();
		}
	});
	searchButton.on('click', (e) => {
		e.preventDefault();
		searchInput.value = '';
		changeSearchInput(searchInput, searchButton, buttonIcon);
		fetchProjects();
	});

	function changeSearchInput(input, button, icon) {
		if (input.value.length > 0) {
			button.disabled = false;
			icon.classList = "icon icon-pluss";
		} else {
			button.disabled = true;
			icon.classList = "icon icon-search";
		}
	}

	loadMoreButton.on('click', getLoadMoreClickHandler());

	function getLoadMoreClickHandler() {
		return (e) => {
			e.preventDefault();
			const page = parseInt(loadMoreButton.dataset['page'], 10);
			loadMoreButton.dataset['page'] = page + 1;
			fetchProjects(false);
			return false;
		};
	}

	projectArchive.$('select').on('change', fetchProjects);

	async function fetchProjects(isReplace = true) {
		if (isReplace) {
			loadMoreButton.classList.remove('hidden');
			loadMoreButton.dataset['lastYear'] = '';
			loadMoreButton.dataset['page'] = 1;
		}
		const { lastYear: currentLastYear } = loadMoreButton.dataset;
		const page = parseInt(loadMoreButton.dataset['page'], 10);
		const formQuery = serializeForm(form);
		const query = `${formQuery}&page=${page}&lastYear=${currentLastYear}`;
		window.history.pushState('', '', `${basePath}?${query}`); // Updates url on current page.
		const url = `${form.action}?${query}`;
		const response = await fetch(url);
		const { listing: nextListing, lastYear, hasMore } = await response.json();
		loadMoreButton.dataset['lastYear'] = lastYear;

		if (!hasMore) {
			loadMoreButton.classList.add('hidden');
		}

		let listingContent;
		if (isReplace) {
			listingContent = nextListing;
		} else {
			listingContent = listingDomNode.innerHTML + nextListing;
		}
		listingDomNode.innerHTML = listingContent;
	}
}

import { serializeForm } from './util/form';

const getNodeById = document.getElementById.bind(document);

export default function initializeFeedback() {
	const feedbackPart = getNodeById('feedback-part');

	if (feedbackPart) {
		const [form] = feedbackPart.$('form');
		const radioButtons = [...feedbackPart.querySelectorAll('input[type="radio"]')];
		const labels = [...feedbackPart.querySelectorAll('.feedback-label')];

		radioButtons.forEach(radioButton => radioButton.addEventListener('click', () => {
			getNodeById('feedback-part__feedback').classList.remove('hidden');
			labels.forEach(label => label.classList.add('hidden'));
			getNodeById(radioButton.value + '-feedback').classList.remove('hidden');
		}));

		// Form handling:
		if (form) {
			form.on('submit', getSubmitHandler(form, feedbackPart));
			getNodeById('feedback-current-url').value = window.location.href;
			getNodeById('feedback-current-width').value = window.innerWidth || document.documentElement.clientWidth ||
				document.body.clientWidth;
		}
	}

	function getSubmitHandler(form, feedbackPart) {
		return async (e) => {
			e.preventDefault();
			const siteKey = form.dataset['key'];
			const { grecaptcha } = window;
			grecaptcha.ready(async () => {
				const token = await grecaptcha.execute(siteKey, { action: 'submit' });

				const [submitButton] = form.$('button');
				submitButton.disabled = true;
				feedbackPart.classList.remove('unsent');
				feedbackPart.classList.add('is-sending');
				const query = serializeForm(form);
				const url = `${form.action}?${query}&token=${token}`;
				const response = await fetch(url, { method: 'POST' });
				feedbackPart.classList.remove('is-sending');
				feedbackPart.classList.add('is-sent');
				submitButton.removeAttribute('disabled');
				const responseData = await response.json();
				responseData.status ? feedbackPart.classList.add('success') : feedbackPart.classList.add('failure');
			});
		};
	}
}

